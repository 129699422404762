.styled-list {
  text-align: left;
  list-style: none;
  padding: 0;

  li {
    margin: 1.5rem 0;
    padding-left: 2rem;
    position: relative;

    &::before {
      content: "";
      width: 12px;
      height: 12px;
      background: $secondary;
      border-radius: 50%;
      position: absolute;
      top: 6px;
      left: 0.5rem;
    }
  }
}

.styled-number-list {
  text-align: left;
  list-style-type: none;
  counter-reset: item 0;
  padding-left: 0;

  li {
    padding-left: 2rem;
    padding-right: 10px;
    counter-increment: item 1;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;

    &:before {
      line-height: 1;
      content: counter(item);
      font-weight: 900;
      font-size: 2.5rem;
      margin-right: 20px;
      color: $secondary;
      margin-left: -2rem;
    }

    &:last-child { margin-bottom: 0; }

    p { margin-bottom: 8px; }
  }

  .list-title {
    font-size: 1.1rem;
    margin-bottom: 4px;
    display: block;
    color: $secondary;
    @include serif;
    font-weight: 600;
  }

  &.neutral-numbers {
    li:before {
      color: rgba($grey, 0.15);
    }
  }

  &.starts-at-5 {
    counter-reset: item 5;
  }

  &.small {
    font-weight: 300;
    font-size: 0.9rem;
    display: flex;
    flex-wrap: wrap;

    li {
      max-width: 50%;
      align-items: flex-start;
    }

    @media (max-width: 991px) {
      li { max-width: 100%; }
    }
  }
}
