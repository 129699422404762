.carousel {
  width: 100%;

  .carousel-item {
    min-height: 300px;
    position: relative;

    @media(max-width: 991px) {
      min-height: 400px
    }
  }

  .carousel-item-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel-indicators {
    li {
      box-sizing: inherit;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      padding: 5px;
      margin-right: 8px;
      margin-left: 8px;
      cursor: pointer;
      border: 2px solid white;
      background-color: transparent;
      box-shadow: 0px 1px 2px rgba(100, 100, 100, 0.2);
      background-clip: inherit;

      &.active { background-color: white; }
    }
  }
}

#heroCarousel {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .carousel-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .carousel-item {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .carousel-item:nth-child(1) {
    background-position: 50% 25%;
  }
}

.carousel {
  &.carousel-hero {
    min-height: 400px;
    max-height: 600px;
    height: 70vh;

    @media(max-width: 991px) {
      min-height: 300px;
      max-height: 350px
    }

    .carousel-inner, .carousel-item { height: 100%; }

    h1, h2 {
      text-shadow: 0px 1px 2px rgba(100,100,100,0.75);
      font-size: 3.5rem;

      .subtitle {
        font-weight: lighter;
      }
    }

    h2 {
      font-size: 3.5rem;
    }

    h1, h2 {
      @media (max-width: 991px) {
        font-size: 1.66rem;
        margin-top: 3rem;

        .subtitle { font-size: 1.2rem; font-weight: normal; }
      }
    }

    .carousel-item {
      .container, .row {
        height: 100%;
      }

      .content-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(black, 0.1);
        padding: inherit;
      }

      .row {
        align-items: center;
      }

      @media (max-width: 991px) {
        .row {
          align-items: baseline;
          justify-content: center !important;
          text-align: center !important;
        }
      }
    }
  }
}

#intro-carousel {
  .carousel-item:nth-child(1) {
    background-image: url(../img/video_poster.jpg);
    background-position: center;
    background-size: cover;
  }

  .carousel-item:nth-child(2) {
    background-image: url(../img/intro_carousel/2.jpg);
    background-position: center;
    background-size: cover;
  }

  .carousel-item:nth-child(3) {
    background-image: url(../img/intro_carousel/3.jpg);
    background-position: left;
    background-size: cover;
  }

  .carousel-item:nth-child(4) {
    background-image: url(../img/intro_carousel/4.jpg);
    background-position: right;
    background-size: cover;
  }

  .carousel-item:nth-child(5) {
    background-image: url(../img/intro_carousel/5.jpg);
    background-position: right;
    background-size: cover;
  }
}

#es-intro-carousel {
  .carousel-item:nth-child(1) {
    background-image: url(../img/promises.png);
    background-position: center;
    background-size: cover;
  }

  .carousel-item:nth-child(2) {
    background-image: url(../img/character.jpg);
    background-position: center;
    background-size: cover;
  }

  .carousel-item:nth-child(3) {
    background-image: url(../img/eagle.jpg);
    background-position: left;
    background-size: cover;
  }

  .carousel-item:nth-child(4) {
    background-image: url(../img/friendship.jpg);
    background-position: right;
    background-size: cover;
  }
}
