.logo {
  max-height: 60px;

  &.big {
    max-height: 100px;
  }

  @media(max-width: 991px) {
    max-height: 50px;
  }
}

.logo-footer {
  max-height: 50px;
}

section {
  position: relative;
  padding: 5rem 0;

  &.intro {
    padding: 5rem 0 8rem;
  }

  &.padding-sm {
    padding: 3rem 0;
  }

  &.pb0 { padding-bottom: 0; }

  @media (max-width: 991px) {
    padding: 3rem 0;
  }

  &.section-img {
    min-height: 400px;
  }
}

.nav-section-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}



.hero {
  padding: 8rem 0;
  min-height: 450px;
  display: flex;
  align-items: center;
  text-align: center;
}

.section-title {
  margin-bottom: 2rem;
}

.overlay {
  background-color: rgba(28, 27, 26, 0.25);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  &.strong {
    background-color: rgba(28, 27, 26, 0.5);
  }
}

.buttons {
  .button { margin: 0 8px 0 0; }
  .note { margin-top: 5px; font-size: 0.8rem; }

  @media (max-width: 991px) {
    .button {
      margin: 0 0 1rem;
      &:last-child { margin-bottom: 0; }
    }
  }
}

.separator-right-lg {
  @media(min-width: 992px) {
    border-right: 4px solid $primary;
    padding-right: 1.5rem;
  }
}

.bg-hero-intro {
  background: no-repeat center 30%;
  background-size: cover;
  padding-top: 15rem;
  padding-bottom: 12rem;
  background-image: url(../img/bg-hero-world.jpg);
}

.bg-Pre-K {
  background: no-repeat center 30%;
  background-size: cover;
  background-image: url(../img/Pre-K-kinder-rograms-page-banner.png);
}

.bg-virtual-tour {
  background: no-repeat center 58%;
  background-size: cover;
  background-image: url(../img/img-virtual.jpg);
}

.bg-fall-2020-hero {
  background: no-repeat center 58%;
  background-size: cover;
  background-image: url(../img/fall-2020/bg-fall2020-two.jpg);
}

.bg-hero-mission {
  background: no-repeat center bottom 30%;
  background-size: cover;
  padding-top: 15rem;
  padding-bottom: 12rem;
  background-image: url(../img/bg-hero-mission.jpg);
}

.bg-forest-intro {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-forest-intro.jpg);
}

.bg-city {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/city.jpg);
}

.bg-city-2 {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/city2.jpg);
}

.bg-collage {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/collage3.jpg);
}

.bg-cta-about {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-cta-about.jpg);
}

.bg-home-frustrated {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-home-frustrated.jpg);
}

.bg-action {
  background: no-repeat 50% 30%;
  background-size: cover;
  background-image: url(../img/bg-action.jpg);
}

.bg-promise-home {
  background: no-repeat left;
  background-size: cover;
  background-image: url(../img/bg-promise-home.jpg);
}

.bg-cbf {
  background: no-repeat top;
  background-size: cover;
  background-image: url(../img/bg-cbf.jpg);
}

.bg-parents {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-parents.jpg);
}

.bg-campus {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-campus.jpg);
}

.bg-elementary-hero {
  background: no-repeat 50% 60%;
  background-size: cover;
  background-image: url(../img/bg-elementary.jpg);
}

.bg-forest-hero {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-forest.jpg);
}

.bg-registration {
  background: no-repeat 50% 60%;
  background-size: cover;
  background-image: url(../img/bg-registration.jpg);
}

.bg-resources {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-resources.jpg);
}

.bg-nest-hero {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-nest-hero.jpg);
}

.bg-middle {
  background: no-repeat 50% 25%;
  background-size: cover;
  background-image: url(../img/bg-middle.jpg);
}

.bg-elementary {
  background-position: bottom center;
  background-size: cover;
  background-image: url(../img/elementary-banner.png);
  min-height: 800px;
}

.bg-cta-community-middle {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-cta-community-middle.jpg);
}

.bg-apply-hero {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-apply-hero.jpg);
}

.bg-index-hero {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/hero-home.png);
}
.bg-different {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-different.jpg);
}

.bg-cta-community-elementary {
  background: no-repeat top;
  background-size: cover;
  background-image: url(../img/bg-cta-community-elementary.jpg);
}

.bg-parents-hero {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/journey-3.jpg);
}

.bg-huddle {
  background: no-repeat center 30%;
  background-size: cover;
  background-image: url(../img/huddle.jpg);
}

.bg-extended {
  background: no-repeat center 20%;
  background-size: cover;
  background-image: url(../img/group.jpg);
}

.bg-bench {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bench.jpg);
}

.bg-tree {
  background: no-repeat center 40%;
  background-size: cover;
  background-image: url(../img/tree.jpg);
}

.bg-yard {
  background: no-repeat center 10%;
  background-size: cover;
  background-image: url(../img/yard.jpg);
}

.testimonial {
  font-size: 1.8rem;
  line-height: 1.7;
  font-weight: 300;

  .content {
    font-weight: 300;
  }

  .attribution {
    font-size: 1rem;
    font-weight: normal;
    margin-top: 1.5rem;
  }

  &.white-letters { color: white; .attribution { color: white;} }

  @media (max-width: 991px) {
    font-size: 1.5rem;
  }

  &.testimonial-sm {
    font-size: 1.5rem;
  }
}

.play {
  max-height: 80px;
}

.signature {
  max-height: 60px;
  max-width: 180px;
}

.editorial {
  text-align: left;

  &.center { text-align: center; }
  .white { color: white; }

  h3 {
    font-size: 1.75rem;
  }

  p {
    font-weight: 300;
    font-size: 15px;
    color: $black;
    line-height: 1.62;
  }
}

.box {
  padding: 30px;
}

.faqs-box {
  text-align: left;
  padding: 0 30px;
  margin: 3rem 0;

  .question-wrapper {
    padding: 30px 0;
    border-bottom: 1px solid rgba(black, 0.075);

    &.active .question::after { background-image: url(../img/icon-chevron-up.png); }
  }

  .question {
    color: $secondary;
    font-weight: 300;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    &::after {
      content: "";
      margin-left: auto;
      padding-left: 50px;
      display: block;
      position: relative;
      width: 100%;
      max-width: 21px;
      height: 11px;
      background-image: url(../img/icon-chevron-down.png);
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .answer {
    display: none;
    font-size: 0.9rem;
    margin-top: 1rem;
    line-height: 1.72;
    p { color: $black; font-weight: 300; }
    p:last-child { margin: 0; }
  }
}

.team-member {
  max-height: 80px;
  border-radius: 50%;
}

.icon {
  max-height: 80px;
}

footer {
  background-color: white;;
  padding: 5rem 0;
  border-top: 1px solid rgba(black, 0.075);

  a {
    color: $grey;
    display: inline-block;
    margin: 1rem;
    font-size: 0.9rem;
    transition: 200ms all;

    &:hover, &:focus, &:active {
      color: lighten($secondary, 15%);
      text-decoration: none;
    }

    @media (max-width: 991px) {
      display: block;
    }
  }

  .copyright {
    font-size: 0.8rem;
  }

  @media (max-width: 991px) {
    text-align: center;
  }
}

.social-icons {
  .social-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 6px;
    height: 48px;
    width: 48px;
    font-size: 24px;
    text-align: center;
    color: white;
    background: $secondary;
    border-radius: 50%;
    transition: 200ms all;

    &:hover {
      background: lighten($blue, 10%);
    }
  }
}

.contact-card {
  display: flex;
  align-items: center;
  font-weight: 300;
  img { margin-right: 1rem; }

  h5 {
    font-size: 1rem;
    margin-bottom: 8px;
    color: $secondary;
    font-weight: bold;
  }

  p {
    margin-bottom: 0;
  }

  @media(max-width: 991px) {
    margin-bottom: 1rem;
  }
}

.feature {
  h5 {
    color: $secondary;
    margin: 1rem 0;
    font-size: 1.4rem;
  }
  p {
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.72;
  }

  .img-wrapper {
    height: 400px;
  }
}

.img-wrapper {
  height: 280px;
  width: auto;
  max-width: 100%;

  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }

  &.vertical {
    height: 400px;
  }
}

.img-mega-wrapper {
  position: relative;
  margin-top: 40px;
  img {
    position: absolute;
    right: 0;
  }

  @media(max-width: 991px) {
    display: none;
  }
}

.img-circle{
  border-radius: 50%;
  width: 100%;
}

.img-featured {
  max-height: 450px;
}

.parents-club-books {
    a {
        font-weight: 700;
        text-decoration: underline;
    }
}

.parents-club-video {
    overflow: hidden;
    iframe {
        background-color: black;
        width: 100%;
        height: 310px;
    }

    p {
        text-align: center;
    }

    &.col-md-4{
        iframe{
            height: 200px;
        }
    }

}

.person-title {
  margin-top: 3px;
  font-size: 0.66em;
  color: $grey;
}

.person-wrapper {
  padding: 3rem 0;

  img {
    max-height: 140px;
    width: auto;
  }
}

.tools-images {
  @media (max-width: 991px) {
    img {
      max-height: 80px;
      max-width: 180px;
    }
  }
}

.navigation-box {
  position: relative;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: 200ms all;

  .box-header {
    padding: 2rem 30px 1rem;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0;
  }

  .box-body {
    padding: 1rem 30px;
    p { font-size: 1rem; }
    p:last-child { margin-bottom: 0; }
  }

  .box-footer {
    margin-top: auto;
    padding: 1rem 30px 2rem;
  }

  &:hover, &:focus, &:active {
    transform: translateY(-5px);
  }
}

.location-row {
  [class*=' col-'], .col-md-4 {
    min-height: 400px;
  }

  .box {
    padding: 5rem 30px;
    height: 100%;
  }
}


.learning-boxes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 0;

  .learning-box {
    display: inline-block;
    vertical-align: top;
    margin: 4px;
    max-width: 400px;
    border-radius: 3px;
    overflow: hidden;

    img {
      max-width: 100%;
    }

    .learning-box-body {
      padding: 30px;
    }

    h2 {
      margin-bottom: 4px;
      font-size: 1.5rem;
      text-align: center;
      font-weight: 600;
    }

    h5 {
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 1rem;
      text-align: center;
      margin-left: 8px;
    }

    hr {
      margin-top: 2rem;
      margin-bottom: 2rem;
      border: 0;
      border-top: 4px solid white;
      max-width: 30px;
      &.black { border-top-color: $black; }
    }

    .highlight {
      font-size: 1.25rem;
      position: relative;
      text-align: center;
      margin: 1rem 0;
    }

    .underline {
      display: inline-block;
    }

  }
}

.box-event {
  padding: 30px;
  border-radius: 3px;
  background-color: white;
  margin-bottom: 4px;

  .title {
    font-weight: 600;
  }

  .button {
    margin-bottom: 0;
  }
}

.schedule {
  text-align: left;

  .time-slot {
    padding: 5px 15px;
    border-radius: 4px;
    display: flex;
    border: dashed 2px $tertiary;
    margin-bottom: 8px;
    color: $tertiary;

    .time {
      width: 75px;
    }
    .description {
      width: 100%;
    }

    &.solid {
      background-color: $primary;
      color: white;
      border: 2px solid $primary;
    }

    &.red {
      background-color: $tertiary;
      border-color: $tertiary;
      color: white;
    }

    &.bg-light-blue {
      border: 2px solid $secondary;
      background-color: $secondary;
    }
  }
}

.deconstructed-logo {
  width: 100%;

  .part {
    position: relative;
    max-width: 50%;
    margin-left: 0;
    transition: 200ms all;

    img {
      max-width: 100%;
    }

    .arrow {
      position: absolute;
      right: 10px;
      bottom: 0;
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: white;
      box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3);
    }

    &:hover {
      cursor: pointer;
      margin-left: 20px;
    }

    &.active {
      margin-left: 50%;

      .arrow {
        display: none;
      }

      .content {
        display: block;
      }

      &:hover { margin-left: calc(50% - 20px);}
    }

    &:first-child { margin-bottom: -14%;}
    &:nth-child(2) { margin-bottom: -10%;}
    &:nth-child(3) { margin-bottom: -5%;}
    &:nth-child(4) { margin-bottom: -3%;}
  }
}

.deconstructed-logo-content {
  display: flex;
  justify-content: flex-end;

  h2 {
    font-weight: 600;
    font-size: 1.5rem;
  }

  .content {
    max-width: 500px;
    display: none;

    &.active {
      display: flex;
      .icon { margin-right: 2rem; }
    }
  }
}

.school-comparison {
  ul {
    text-align: left;
    list-style-type: none;
    counter-reset: item 0;
    padding-left: 0;
    margin-top: 2rem;
  }

  li {
    padding-left: 2rem;
    padding-right: 10px;
    counter-increment: item 1;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    color: $black;

    .content {
      @media(max-width: 991px) {
        min-height: 120px;
        font-size: 0.8rem;
      }
    }

    &:before {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      content: counter(item);
      font-weight: 900;
      width: 3rem;
      height: 3rem;
      min-width: 3rem;
      min-height: 3rem;
      border-radius: 50%;
      border: 2px solid $tertiary;
      font-size: 1.5rem;
      margin-right: 1rem;
      color: white;
      margin-left: -2rem;
      background-color: $tertiary;
      border-color: transparent;
    }

    &:last-child { margin-bottom: 0; }
  }

  ul.grey {
    li:before {
      background: white;
      color: $light-grey;
      border-color: $light-grey;
    }
  }

  @media(max-width: 991px) {
    h2 {
      height: 50px;
      font-weight: bold;
      font-size: 1rem;
      margin-bottom: 0;
    }

    li .content {
      min-height: 100px;
      display: flex;
      align-items: center;
    }
  }
}

.img-cont-16-9{
  width: 100%;
  height: 0;
  box-sizing: content-box;
  position: relative;
  padding-top: 56.25%;

  img{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.video-link .img-cont-16-9{
  position: relative;
  &:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    background: rgba(0,0,0,.3) url(../img/videos/icon-play.png) no-repeat center center;
    background-size: 60px 60px;
    opacity: 0;
    transition: .2s ease-in opacity;
  }
  &:hover{
    &:after{opacity: 1;}
  }
}

.map{
  iframe{
    width: 100%;
    display: block;
  }
}

.calendar-titles
  p {
  margin-bottom: 2.5rem;
}

.press-logo {
  padding: 0 18px;
  @media (max-width: 767px) {
    max-width: 35%;
  }
}

.email-container {
  margin-bottom: 40px;
}

.pills {
  text-align: center;

  .pill {
    display: inline-block;
    margin: 0 5px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: bold;
    color: $grey;
    background: $bg-grey;
    padding: 5px 20px;
    cursor: pointer;
    transition: 200ms all;

    &.active {
      color: white;
      background: $primary;
    }
  }
}

.card-thumbnail {
  min-height: 218px;
  border: none;
  cursor: pointer;
  margin: 10px !important;
  color: white;
  display: flex;
  flex-direction: column;
  transition: 200ms all;
  width: 100%;
  max-width: 320px;

  .card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .card-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: white;
    margin-bottom: 0.25rem;
  }

  .author {
    font-size: 1rem;
    display: block;
    font-size: 0.8rem;
  }

  .date {
    display: block;
    font-size: 1rem;
    margin-top: auto;
    font-size: 0.8rem;
  }

  a {
    color: white !important;
    display: block;
    width: 100%;
    height: 100%;
    &:hover {
      text-decoration: none;
    }
  }

  .card-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    height: 3em;
    width: 3em;
    margin-bottom: 1rem;
    border-radius: 50%;
    background-color: rgba(white, 0.1);

    img {
      height: 50%;
      width: 50%;
    }
  }

  &:hover, &:focus, &:active {
    transform: scale(1.05);
  }

  &.hidden {
    display: none;
  }
}

.card-video {
  background-color: #7E78D2;
}

.card-link {
  background-color: #1d8a99;
}

.card-printable {
  background-color: #05a8aa;
}

.block-container {
  padding: 80px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.075);
}

.block-container-nm {
  padding: 80px 0;
}

.sticky-top {
  z-index: 10;
  top: 116px;
}
.authority-section p{
  margin-bottom: 20px;
}
.centered-list{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 32px;
}
.divisor{
  height: 1px;
  width: 100%;
  background-color: rgb(229, 229, 229);
  margin: auto auto;
}
.icon-home-img{
  max-width: 60px;
}
.icon-home-wrapper{
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 10px;
  p{
    text-align: left;
  }
}
.icons-home{
  column-gap: 40px;
}
.studio-home-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.studio-home-img{
  height: 300px;
  width: 100%;
  object-fit: cover;
}

ul.styled-number-list li:first-child::before{
  padding-right: 4px;
}