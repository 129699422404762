.navbar.navbar-light {
  position: fixed;
  top: 0;
  padding: 0 1rem;
  background: white;
  width: 100%;
  height: 100px;
  z-index: 10;

  .navbar-brand {
    max-width: 220px;
    margin-left: 10px;
    img { max-width: 100%; }
  }

  .navbar-toggler {
    color: $primary;
    border: 0;
    &:hover, &:focus, &:active { outline: none; }
  }

  .navbar-nav {
    align-items: center;
  }

  .navbar-nav .nav-link {
    position: relative;
    font-weight: 500;
    font-size: 0.85rem;
    color: $secondary;
    text-transform: uppercase;
    padding: 0 8px;
    transition: 200ms all;

    &.active::before, &:hover::before {
      content: "";
      position: absolute;
      display: block;
      top: -0.6rem;
      left: 3px;
      height: 5px;
      width: calc(100% - 6px);
      background-color: white;
      opacity: 0;
      transition: 200ms all;
    }

    &.active, &:hover, &:focus, &:active {
      color: white;
    }

    &.button {
      text-transform: none;
      color: white;
      background-color: $primary;
      border-color: $primary;
      margin: 0;
      margin-left: 20px;
      padding: 0.75rem 1rem;
      &::before { content: none; }
    }

    @media (min-width: 992px) {
      &.active::before, &:hover::before, &:focus::before, &:active::before {
        opacity: 1;
      }
    }
  }

  .dropdown {
    z-index: 1000;

    .nav-link {
      padding: 0 1rem;
      &.active::before, &:hover::before { content: none; }
    }

    .dropdown-menu .nav-link {
      color: $black;
      transition: 200ms all;
      text-transform: none;
      z-index: 1000;

      &:hover {
        background-color: $bg-grey;
      }

      &.active {
        font-weight: bold;
      }
    }
  }

  @media(max-width: 991px) {
    padding: 8px;
  }
}

.sidebar {
  text-align: left;
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  top: 0;
  z-index: 300;
  bottom: 0;
  padding: 2rem 0;
  margin: 0;
  right: 0;
  border: 0;
  width: 250px;
  background-color: $bg-grey;
  right: -300px;
  transition: right 100ms;
  overflow-y: scroll;

  &.active {
    right: 0;
    transition: right 100ms;
    box-shadow: 0 2px 10px rgba(0,0,0,0.3);
  }

  .navbar-nav {
    height: 100%;
  }

  .nav-item .nav-link {
    font-weight: 500;
    font-size: 1rem;
    color: $secondary;
    padding: 8px 20px;
    transition: 200ms all;

    &.active { color: $secondary; }

    &.button { color: $secondary; border-color: $secondary; margin: 8px 20px; }
  }

  .nav-item.indented {
    padding-left: 16px;
  }

  .bottom {
    text-align: center;
    padding-bottom: 30px;
    padding-top: 30px;
    padding: 30px;
    margin-top: auto;
  }
}

body.sidebar-active {
  overflow: hidden;
  transition: 50ms all;

  .sidebar-overlay {
    content: "";
    display: block;
    z-index: 200;
    position: fixed;
    height: 500px;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    pointer-events: auto;
    transition: 50ms all;
  }
}

.sidebar-close {
  z-index: 9994;
  width: 45px;
  height: 45px;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-appearance: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding: 0;
  color: rgba($secondary, 0.6);
  font-style: normal;
  font-size: 30px;
  line-height: 45px;
  border: 0;
  background: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  img { margin-right: 1.5rem; }
  @include serif;
  font-size: 1.5rem;

  font-weight: 500;

  .affiliation {
    font-style: italic;
    font-size: 0.7em;
    font-weight: 300;
  }

  @media (max-width: 1199px) {
    .logo-text {
      display: flex;
      flex-direction: column;
    }
  }
}

.pb-sticky-bar {
  position: relative;
  font-size: 16px;
  padding: 10px 0;
  background: $tertiary;
  color: $secondary;
  text-decoration: none;

  &:hover {
    color: $secondary;
    background-color: darken($tertiary, 5%);
  }
}

.navbar-wrapper {
  position: fixed;
  top: 79px;
  width: 100%;
  z-index: 20;
}

.nav-link-phone {
  color: $secondary;
  padding: 0px 8px;
  &::before {
    content: "";
    width: 12px;
    height: 12px;
    display: inline-block;;
    background: url(../img/icon-phone.svg);
    background-size: contain;
    margin-right: 8px;
  }
  &:hover {
    color: white !important;
    text-decoration: none;
  }
  @media (max-width: 767px) {
    &::before {
      background: url(../img/icon-phone-blue.svg);
      background-size: contain;
      margin-left: 14px;
    }
    color: #003a68 !important;
    &:hover {
      color: #003a68 !important;
    }
  }
}
.navbar-footer{
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}